var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.officialClubs.data && _vm.officialClubs.data.length > 0)?_c('CBox',{attrs:{"px":"20px"}},[_c('Loading',{attrs:{"active":_vm.isLoading}}),_c('JoinClub',{attrs:{"isModalOpen":_vm.isModalOpen,"is_need_approval":_vm.currentIsNeedApproval,"slug":_vm.currentSlug,"title":_vm.currentTitle,"rule":_vm.currentRule},on:{"closeModal":_vm.closeModal}}),(_vm.officialClubs.data && _vm.officialClubs.data.length > 0)?_c('CBox',[_c('CFlex',{attrs:{"justifyContent":"space-between"}},[_c('CText',{attrs:{"fontFamily":"Montserrat","fontStyle":"normal","fontSize":"20px","fontWeight":"700","color":"fontMain"}},[_vm._v(" Choose Main Club ")])],1),_c('CFlex',{attrs:{"flexWrap":"wrap"}},_vm._l((_vm.officialClubs.data),function(v,i){return _c('CBox',{key:i,attrs:{"bg":"white","w":[
          'calc(100% - 0px)',
          'calc(33.33% - 14px)',
          'calc(33.33% - 14px)',
          'calc(32.90% - 14px)',
        ],"mr":['0', '14px', '14px', '14px'],"border":"1px solid #E2E8F0","borderRadius":"8px","my":"2","overflow":"hidden","d":"flex"}},[_c('CBox',{attrs:{"top":"0","w":"100%","d":"flex","justifyContent":"space-between","alignItems":"center","p":"4px"}},[_c('CImage',{attrs:{"src":v.logo ? v.logo : require('@/assets/img/default-profile.png'),"alt":"Corner Image","w":"50px","h":"50px","borderRadius":"5","object-fit":"cover","mr":"8px","ml":"6px"}}),_c('CBox',{attrs:{"w":"full"}},[_c('CLink',{attrs:{"href":`/clubs/${v.slug}`,"color":"#0278B7","d":"inline-block"}},[_c('CText',{attrs:{"color":"#0278B7","fontWeight":"600","fontSize":"14px","d":"inline-block","_hover":{ textDecoration: 'underline' }}},[_vm._v(" "+_vm._s(_vm.truncateText(v.name, 15))+" ")])],1),_c('CBox',{attrs:{"d":"flex"}},[_c('CText',{attrs:{"fontSize":"10px","fontWeight":"400","ml":"1","d":"flex","color":"#9e9e9e"}},[_c('CText',{attrs:{"color":"#4a4a4a","fontWeight":"600","mr":"4px"}},[_vm._v(" "+_vm._s(v.total_member)+" ")]),_vm._v(" Members ")],1)],1),(v.status === 'pending')?_c('CButton',{attrs:{"bg":_vm.isUrlCopied !== i ? 'white' : 'main',"color":_vm.isUrlCopied !== i ? 'main' : 'white',"border":_vm.isUrlCopied !== i ? '#29AAFE 1px solid' : '',"_hover":{ opacity: '0.8' },"size":"0","py":"10px","px":"12px","h":"0","w":"80px","fontSize":"10px","fontWeight":"600"},on:{"click":function($event){return _vm.detailClubHandler(v.slug)}}},[_vm._v(" Requested ")]):(
                (_vm.currentUser &&
                  !_vm.currentUser.urole_id &&
                  v.is_joined === 0 &&
                  v.status === 'decline') ||
                (_vm.currentUser &&
                  !_vm.currentUser.urole_id &&
                  v.is_joined === 0 &&
                  v.status === 'not_joined') ||
                (_vm.currentUser &&
                  !_vm.currentUser.urole_id &&
                  v.is_joined === 0 &&
                  v.status === 'accept') ||
                !_vm.currentUser
              )?_c('CButton',{attrs:{"bg":"main","color":"white","_hover":{ opacity: '0.95' },"size":"0","py":"10px","px":"12px","h":"0","w":"80px","fontSize":"10px","fontWeight":"600"},on:{"click":function($event){_vm.currentUser ? _vm.openModal(i) : _vm.redirectToSignIn()}}},[_vm._v(" Join ")]):(
                (!_vm.currentUser.urole_id && v.is_joined !== 0) ||
                !_vm.currentUser ||
                _vm.currentUser.urole_id
              )?_c('CButton',{attrs:{"bg":_vm.isUrlCopied !== i ? 'white' : 'main',"color":_vm.isUrlCopied !== i ? 'main' : 'white',"border":_vm.isUrlCopied !== i ? '#29AAFE 1px solid' : '',"_hover":{ opacity: '0.8' },"size":"0","py":"10px","px":"12px","h":"0","w":"80px","fontSize":"10px","fontWeight":"600"},on:{"click":function($event){return _vm.detailClubHandler(v.slug)}}},[_vm._v(" Detail ")]):_vm._e()],1)],1)],1)}),1)],1):_c('CBox',[_c('CBox',{attrs:{"bg":"white","d":"flex","alignItems":"center","justifyContent":"center","rounded":"md","py":"2","shadow":"sm","mt":"4"}},[(!_vm.query)?_c('CText',{attrs:{"textAlign":"center"}},[_vm._v(" You don't have a club yet, join club ")]):_c('CText',{attrs:{"textAlign":"center"}},[_vm._v(" Clubs containing the word '"),_c('CText',{attrs:{"d":"inline-block","fontWeight":"semibold"}},[_vm._v(_vm._s(_vm.query))]),_vm._v("', were not found ")],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }