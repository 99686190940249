<template>
  <CBox
    v-if="jobFilters.length"
    borderRadius="5px"
    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    bg="white"
    py="25px"
    mb="10px"
    display="flex"
    flexDirection="column"
    alignItems="flex-start"
  >
    <CBox pb="20px" mx="20px">
      <CText
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="16px"
        fontWeight="600"
        color="#4A4A4A"
        >Related Job</CText
      >
    </CBox>

    <CBox
      bg="white"
      px="20px"
      pb="5px"
      class="job-details"
      :style="{ maxHeight: showMore ? '1000px' : '200px' }"
    >
      <div style="padding-bottom: 5px" v-for="(v, i) in jobFilters" :key="i">
        <!-- Job Title -->
        <a
          :href="generateJobUrl(v.id, v.title, v.company.name)"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CText
            fontFamily="Montserrat"
            fontSize="14px"
            fontWeight="600"
            color="rgba(0, 0, 255, 0.7)"
            mb="5px"
          >
            {{ v.title }}
          </CText>
        </a>

        <!-- Company Name -->
        <CText
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="600"
          color="gray"
          mb="5px"
        >
          {{ v.company.name }} | {{ v.type }} | {{ v.province.join(", ") }}
        </CText>
      </div>
    </CBox>

    <!-- CBox for Lihat Semua Link -->
    <CBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      px="20px"
    >
      <!-- Show More/Show Less Button -->
      <div
        v-if="allJobFilters.length > 2"
        @click="toggleShowMore"
        class="pointer"
        fontFamily="Montserrat"
        style="
          color: #16abf8;
          font-weight: 600;
          font-size: 15px;
          padding-top: 10px;
        "
      >
        {{ showMore ? "Lihat Sedikit" : "Lihat Semua" }}
      </div>
    </CBox>
  </CBox>
</template>

<script>
import { GET_JOBS_FILTER } from "@/store/job.module";
import config from "@/ConfigProvider";
import shuffle from "lodash/shuffle";

export default {
  props: {
    keyword: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      urlGethired: config.value("GETHIRED_WEB_URL"),
      allJobFilters: [], // Store all jobs here for consistent shuffling
      jobFilters: [],
      showMore: false,
    };
  },
  computed: {
    currentJobFilters() {
      return this.$store.getters.currentJobFilters;
    },
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        await this.$store.dispatch(GET_JOBS_FILTER, {
          query: this.keyword,
        });

        // Store all jobs for consistent shuffling
        this.allJobFilters = shuffle(this.currentJobFilters);

        this.showMore ? this.showAllJobs() : this.loadInitialJobs();
      } catch (err) {
        console.error(err);
      }
    },
    loadInitialJobs() {
      // Load the initial set of jobs (2 jobs)
      this.jobFilters = this.allJobFilters.slice(0, 2);
    },
    showAllJobs() {
      // Show all jobs
      this.jobFilters = this.allJobFilters;
    },
    toggleShowMore() {
      // Toggle between "Show More" and "Show Less"
      this.showMore = !this.showMore;
      if (this.showMore) {
        this.showAllJobs();
      } else {
        this.loadInitialJobs();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    },
    generateJobUrl(id, title, companyName) {
      const slug = `jobs/${id}-lowongan-kerja-${this.slugify(
        title
      )}-${this.slugify(companyName)}`;
      return `${this.urlGethired}/${slug}`;
    },
    slugify(text) {
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-")
        .replace(/[^\w-]+/g, "");
    },
  },
};
</script>
