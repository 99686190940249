<template>
  <CBox
    borderRadius="5px"
    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    mt="5"
    mx="auto"
    bg="white"
    py="20px"
    px="20px"
  >
    <Loading :active="isLoading" />
    <CFlex justifyContent="space-between" alignItems="center">
      <CText
        fontFamily="Montserrat"
        fontStyle="normal"
        fontSize="16px"
        fontWeight="600"
        color="fontMain"
        >Your Club</CText
      >
      <CLink
        v-if="!currentUser.urole_id && currentUser"
        :href="`/clubs/new`"
        color="main"
        d="inline-block"
        :_hover="{ textDecoration: 'none', opacity: 0.8 }"
        ml="2"
      >
        <CText
          bg="main"
          color="white"
          w="fit-content"
          px="10px"
          py="2px"
          borderRadius="5"
          d="flex"
          size="0"
          justifyContent="center"
          fontSize="12px"
          alignItems="center"
          fontWeight="600"
          lineHeight="26px"
        >
          <CImage
            :src="require('@/assets/icon/icon-plus.svg')"
            mr="1"
            w="12px"
          />
          Create Club</CText
        >
      </CLink>
    </CFlex>
    <CBox v-if="clubs.data && clubs.data.length > 0">
      <CBox v-for="(v, i) in clubs.data" :key="i">
        <CBox
          bg="white"
          overflow="hidden"
          borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
          rounded="md"
          my="2"
        >
          <CFlex justify="space-between" mt="2" px="2">
            <CFlex border="red" justify="space-beetween">
              <CImage
                :src="
                  v.logo ? v.logo : require('@/assets/img/default-profile.png')
                "
                alt="Corner Image"
                w="50px"
                h="50px"
                mb="4"
                mr="10px"
                borderRadius="5"
              />
              <CBox border="red">
                <CLink
                  :href="`/clubs/${v.slug}`"
                  color="#0278B7"
                  d="inline-block"
                  alignItems="center"
                >
                  <CText color="#0278B7" fontWeight="600" fontSize="14px">{{
                    truncateText(v.name, 20)
                  }}</CText>
                </CLink>
                <CText fontSize="10px" color="#000" fontWeight="600">{{
                  truncateText(v.location, 20)
                }}</CText>
              </CBox>
            </CFlex>
            <CBox
              d="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              cursor="pointer"
              :_hover="{ opacity: 0.8 }"
              @click.native="
                $route.params.track
                  ? $router.push({
                      name: 'Track Members Club',
                      params: {
                        track: $route.params.track,
                        slug: v.slug,
                      },
                    })
                  : $router.push({
                      name: 'Members Club',
                      params: {
                        slug: v.slug,
                      },
                    })
              "
            >
              <CText
                color="#000"
                fontWeight="600"
                fontSize="10"
                d="flex"
                alignItems="center"
                textAlign="end"
              >
                {{ v.total_member }}
                <CText color="#4a4a4a" fontSize="10px" fontWeight="400" ml="1">
                  Members</CText
                >
              </CText>
            </CBox>
          </CFlex>
        </CBox>
      </CBox>
    </CBox>
    <CBox v-else>
      <CBox
        bg="white"
        d="flex"
        alignItems="center"
        justifyContent="center"
        rounded="md"
        py="2"
        shadow="sm"
        mt="4"
        mb="8px"
      >
        <CText v-if="!query" textAlign="center" d="flex" fontSize="14px"
          >You don't have a club yet,
          <CText
            @click.native="
              $route.params.track
                ? $router.push({
                    name: 'Track Club',
                    params: {
                      track: $route.params.track,
                    },
                    query: query,
                  })
                : $router.push({
                    name: 'Club',
                  })
            "
            color="main"
            cursor="pointer"
            :_hover="{ textDecoration: 'underline' }"
            mx="6px"
          >
            join</CText
          >
          club</CText
        >
        <CText v-else textAlign="center"
          >Clubs containing the word '<CText
            d="inline-block"
            fontWeight="semibold"
            >{{ query }}</CText
          >', were not found</CText
        >
      </CBox>
    </CBox>
    <CText
      fontFamily="Montserrat"
      fontStyle="normal"
      fontSize="14px"
      fontWeight="600"
      color="activeMenu"
      textAlign="center"
      class="pointer"
      @click.native="
        $route.params.track
          ? $router.push({
              name: 'Track Club',
              params: {
                track: $route.params.track,
              },
              query: query,
            })
          : $router.push({
              name: 'Club',
            })
      "
      mt="16px"
      >Browse Club</CText
    >
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CBox, CImage, CText, CFlex, CLink } from "@chakra-ui/vue";
import Swal from "sweetalert2";

export default {
  components: {
    CBox,
    CImage,
    CText,
    CFlex,
    CLink,
    Loading,
  },
  data() {
    return {
      currentClubIndex: null,
      isLoading: false,
      currentPage: 1,
      currentSlug: "",
      currentTitle: "",
      currentRule: "",
      isModalOpen: false,
      isUrlCopied: -1,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    totalPages() {
      return Math.ceil(this.clubs.meta.total / this.clubs.meta.limit);
    },
    query() {
      return this.$route.query.q;
    },
    clubs() {
      return this.$store.getters.clubs;
    },
  },
  methods: {
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    formatDate(dateString) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const date = new Date(dateString);
      const monthNumber = date.getMonth() + 1; // Months are zero-based
      const year = date.getFullYear();

      return `${monthNumber} ${months[monthNumber - 1]} ${year}`;
    },
    openModal(index) {
      this.currentSlug = this.clubs.data[index].slug;
      this.currentTitle = this.clubs.data[index].name;
      this.currentRule = this.clubs.data[index].rule;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    getTypeLabel(type) {
      return type === "komunitas"
        ? "Community"
        : type === "kampus"
        ? "University"
        : type === "perusahaan"
        ? "Company"
        : "Event";
    },
    setCurrentPage(page) {
      this.currentPage = page;
    },
    copyUrl(index) {
      const inviteUrl = window.location + "/" + this.clubs.data[index].slug;
      const dummyInput = document.createElement("input");
      document.body.appendChild(dummyInput);
      dummyInput.value = inviteUrl;
      dummyInput.select();
      document.execCommand("copy");
      document.body.removeChild(dummyInput);

      // Update the state to indicate that the URL has been copied
      this.isUrlCopied = index;
      Swal.fire({
        title: "Copied",
        text: "Club " + this.clubs.data[index].name + " url ready to share.",
        icon: "success",
      });
      // Reset the state after a certain duration (e.g., 2 seconds)
      setTimeout(() => {
        this.isUrlCopied = -1;
      }, 2000);
    },
  },
};
</script>
