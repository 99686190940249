<template>
  <CBox
    border="1px solid #E2E8F0"
    W="100%"
    borderRadius="8px"
    padding="4"
    mx="5"
    mb="3"
  >
    <CBox display="flex" justifyContent="space-between">
      <CBox>
        <CText
          fontFamily="Montserrat"
          fontSize="11px"
          fontWeight="600"
          color="#9e9e9e"
        >
          {{ test.created_at_str }}
        </CText>
      </CBox>
      <CBox>
        <CBox display="flex" justifyContent="space-between">
          <CText
            v-if="$route.params.track"
            @click.native="
              $router.push({
                name: 'Track Detail Job',
                params: { slug: $route.params.track, id: test.id },
              })
            "
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="700"
            color="activeMenu"
            class="pointer"
            mr="3"
          >
            Detail
          </CText>
          <CText
            v-else
            @click.native="
              $router.push({
                name: 'Detail Job',
                params: { id: test.id },
              })
            "
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="700"
            color="activeMenu"
            class="pointer"
            mr="3"
          >
            Detail
          </CText>
          <CText
            v-if="$route.params.track"
            @click.native="
              $router.push({
                name: 'Track Detail Log',
                params: { track: $route.params.track, id: test.id },
              })
            "
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="700"
            color="activeMenu"
            class="pointer"
          >
            Log
          </CText>
          <CText
            v-else
            @click.native="
              $router.push({
                name: 'Detail Log',
                params: { id: test.id },
              })
            "
            fontFamily="Montserrat"
            fontSize="12px"
            fontWeight="700"
            color="activeMenu"
            class="pointer"
          >
            Log
          </CText>
        </CBox>
      </CBox>
    </CBox>
    <CGrid
      v-if="defaultMetricsHeader === 'Default Result'"
      :templateColumns="[
        '6fr 6fr',
        '6fr 6fr',
        '6fr 6fr',
        '2fr 3fr 2fr 2fr 2fr',
      ]"
      :gap="2"
      h="inherit"
      pt="2"
    >
      <CBox>
        <CText
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="600"
          display="block"
          color="#9e9e9e"
          mb="1"
        >
          Job ID
        </CText>
        <CText
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="600"
          color="fontMain"
        >
          {{ test.id }}
        </CText>
      </CBox>
      <CBox>
        <CText
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="600"
          display="block"
          color="#9e9e9e"
          mb="1"
        >
          App Response
        </CText>
        <CBox>
          <CBox
            v-if="checkEmpObject(test.result)"
            bg="bgWaiting"
            borderRadius="full"
            w="20"
          >
            <CBox w="20px" h="inherit" d="table-cell" verticalAlign="middle">
              <CImage
                :src="require('@/assets/icon/ic-clock.svg')"
                pl="5px"
                py="5px"
              />
            </CBox>
            <CBox
              d="table-cell"
              verticalAlign="middle"
              h="inherit"
              pl="5px"
              pr="10px"
            >
              <CText
                fontFamily="Montserrat"
                color="white"
                fontSize="12px"
                fontWeight="600"
              >
                Waiting
              </CText>
            </CBox>
          </CBox>
          <CText
            v-else
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            fontWeight="600"
            color="fontMain"
          >
            {{ test.result.totalDurationText || test.total_duration_str }} ms
          </CText>
          <!-- <CText
            v-else
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            fontWeight="600"
            color="fontMain"
          >
            {{
              test.result.metrics_value["Request per Second"][
                "GET 1000 request 1 concurrency"
              ]
            }}
          </CText> -->
        </CBox>
      </CBox>
      <CBox>
        <CText
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="600"
          display="block"
          color="#9e9e9e"
          mb="1"
        >
          Score
        </CText>
        <CBox>
          <CBox
            v-if="checkEmpObject(test.result)"
            bg="bgWaiting"
            borderRadius="full"
            w="20"
          >
            <CBox w="20px" h="inherit" d="table-cell" verticalAlign="middle">
              <CImage
                :src="require('@/assets/icon/ic-clock.svg')"
                pl="5px"
                py="5px"
              />
            </CBox>
            <CBox
              d="table-cell"
              verticalAlign="middle"
              h="inherit"
              pl="5px"
              pr="10px"
            >
              <CText
                fontFamily="Montserrat"
                color="white"
                fontSize="12px"
                fontWeight="600"
              >
                Waiting
              </CText>
            </CBox>
          </CBox>
          <CText
            v-else
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            fontWeight="600"
            color="fontMain"
          >
            {{ test.total_point }}
          </CText>
        </CBox>
      </CBox>
      <CBox>
        <CText
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="600"
          display="block"
          color="#9e9e9e"
          mb="1"
        >
          Failed Count
        </CText>
        <CBox
          v-if="checkEmpObject(test.result)"
          bg="bgWaiting"
          borderRadius="full"
          w="20"
        >
          <CBox w="20px" h="inherit" d="table-cell" verticalAlign="middle">
            <CImage
              :src="require('@/assets/icon/ic-clock.svg')"
              pl="5px"
              py="5px"
            />
          </CBox>
          <CBox
            d="table-cell"
            verticalAlign="middle"
            h="inherit"
            pl="5px"
            pr="10px"
          >
            <CText
              fontFamily="Montserrat"
              color="white"
              fontSize="12px"
              fontWeight="600"
            >
              Waiting
            </CText>
          </CBox>
        </CBox>
        <template v-if="!checkEmpObject(test.result)">
          <CText
            v-if="test.result.failed > 0"
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            fontWeight="600"
            color="failedColor"
            @click.native="
              $router.push({
                name: 'Detail Job',
                params: { id: test.id },
              })
            "
            class="pointer"
          >
            {{ test.result.failed }}
          </CText>
          <CBox
            v-if="test.result.failed === 0"
            d="flex"
            bg="passedColor"
            borderRadius="full"
            w="20"
            justifyContent="center"
          >
            <CStack
              d="table-cell"
              direction="row"
              h="inherit"
              verticalAlign="middle"
            >
              <CBox d="table-cell" verticalAlign="middle" h="inherit">
                <CIcon name="check" color="white" size="10px" />
              </CBox>
              <CBox d="table-cell" verticalAlign="middle" h="inherit" pl="5px">
                <CText
                  lineHeight="25px"
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="12px"
                  color="white"
                >
                  Passed
                </CText>
              </CBox>
            </CStack>
          </CBox>
        </template>
      </CBox>
      <CBox position="relative">
        <CBox
          position="absolute"
          bottom="0"
          right="0"
          display="inline-flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <template v-if="test.is_personal_record">
            <CBox
              bg="passedColor"
              borderRadius="50%"
              align="center"
              width="4"
              height="4"
            >
              <CIcon fontSize="10" mt="-2" name="arrow-up" color="white" />
            </CBox>
            <CText
              fontFamily="Montserrat"
              fontSize="12px"
              fontWeight="600"
              color="passedColor"
              ml="1"
            >
              New PR!
            </CText>
          </template>
          <CImage
            v-if="test.current_leaderboard_rank == 1"
            :src="require('@/assets/icon/ic-first-medal.png')"
            ml="2"
          />
          <CImage
            v-if="test.current_leaderboard_rank == 2"
            :src="require('@/assets/icon/ic-second-medal.png')"
            ml="2"
          />
          <CImage
            v-if="test.current_leaderboard_rank == 3"
            :src="require('@/assets/icon/ic-third-medal.png')"
            ml="2"
          />
        </CBox>
      </CBox>
    </CGrid>
    <CGrid
      v-else
      :templateColumns="[
        '6fr 6fr',
        '6fr 6fr',
        '6fr 6fr',
        '2fr 2fr 2fr 2fr 3fr',
      ]"
      :gap="2"
      h="inherit"
      pt="4"
    >
      <CBox>
        <CText
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="600"
          display="block"
          color="#9e9e9e"
          mb="1"
        >
          Job ID
        </CText>
        <CText
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="600"
          color="fontMain"
        >
          {{ test.id }}
        </CText>
      </CBox>
      <CBox v-for="(label, i) in metricsLabels" :key="i">
        <CText
          fontFamily="Montserrat"
          fontSize="12px"
          fontWeight="600"
          display="block"
          color="#9e9e9e"
          mb="1"
        >
          {{ label }}
        </CText>
        <CBox>
          <CText
            fontFamily="Montserrat"
            fontStyle="normal"
            fontSize="14px"
            fontWeight="600"
            color="fontMain"
          >
            {{
              checkMetricsDataObject(label)
                ? formatMetrics(
                    test.result.metrics_value[label][defaultMetricsHeader],
                    label
                  )
                : "-"
            }}
          </CText>
        </CBox>
      </CBox>
    </CGrid>
    <CBox my="2" display="inline-flex">
      <CText
        v-for="(tag, k) in test.hashtags"
        :key="k"
        fontFamily="Montserrat"
        fontSize="12px"
        fontWeight="600"
        color="activeMenu"
        mr="1"
      >
        <span
          class="pointer"
          @click="
            $route.params.track
              ? currentAppType.view_type != 'RECRUITMENT' &&
                $router.push({
                  name: 'Track Leaderboard',
                  params: { track: $route.params.track, slug: appTypeId },
                  query: { hashtag: tag.name },
                })
              : currentAppType.view_type != 'RECRUITMENT' &&
                $router.push({
                  name: 'Leaderboard',
                  params: { slug: appTypeId },
                  query: { hashtag: tag.name },
                })
          "
          >#{{ tag.name }}
        </span>
        <span v-if="test.hashtags.length !== k + 1">,</span>
      </CText>
    </CBox>
    <CBox>
      <CText
        fontFamily="Montserrat"
        fontSize="12px"
        fontWeight="600"
        display="block"
        color="#9e9e9e"
        mb="1"
      >
        Description
      </CText>
      <CText
        fontFamily="Montserrat"
        fontSize="14px"
        fontWeight="600"
        color="fontMain"
        display="block"
      >
        {{ test.site_name }}
      </CText>
    </CBox>
  </CBox>
</template>

<script>
export default {
  name: "JobResultItem",
  props: {
    test: Object,
    currentAppType: Object,
    metricsLabels: Array,
    defaultMetricsHeader: String,
    appTypeId: Number,
    checkEmpObject: Function,
  },
  methods: {
    checkMetricsDataObject(label) {
      if (Object.hasOwn(this.test.result, "metrics_value")) {
        return (
          Object.hasOwn(this.test.result.metrics_value, label) &&
          Object.hasOwn(
            this.test.result.metrics_value[label],
            this.defaultMetricsHeader
          )
        );
      } else {
        return false;
      }
    },
    formatMetrics(value, label) {
      switch (label) {
        case "CPU":
          return Math.round(value * 100) / 100 + "%";
        case "Memory":
          return parseFloat(value / 1000000).toFixed(1) + "MB";
        case "Time Duration":
          return parseFloat(value / 1000).toFixed(2) + "s";
        case "Request per Second":
          return Math.round(value) + "";
        default:
          return value + "";
      }
    },
  },
};
</script>
