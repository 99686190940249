<template>
  <CBox v-if="officialClubs.data && officialClubs.data.length > 0" px="20px">
    <Loading :active="isLoading" />
    <JoinClub
      :isModalOpen="isModalOpen"
      :is_need_approval="currentIsNeedApproval"
      :slug="currentSlug"
      :title="currentTitle"
      :rule="currentRule"
      @closeModal="closeModal"
    />
    <CBox v-if="officialClubs.data && officialClubs.data.length > 0">
      <CFlex justifyContent="space-between">
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="20px"
          fontWeight="700"
          color="fontMain"
        >
          Choose Main Club
        </CText>
      </CFlex>
      <CFlex flexWrap="wrap">
        <CBox
          v-for="(v, i) in officialClubs.data"
          :key="i"
          bg="white"
          :w="[
            'calc(100% - 0px)',
            'calc(33.33% - 14px)',
            'calc(33.33% - 14px)',
            'calc(32.90% - 14px)',
          ]"
          :mr="['0', '14px', '14px', '14px']"
          border="1px solid #E2E8F0"
          borderRadius="8px"
          my="2"
          overflow="hidden"
          d="flex"
        >
          <CBox
            top="0"
            w="100%"
            d="flex"
            justifyContent="space-between"
            alignItems="center"
            p="4px"
          >
            <CImage
              :src="
                v.logo ? v.logo : require('@/assets/img/default-profile.png')
              "
              alt="Corner Image"
              w="50px"
              h="50px"
              borderRadius="5"
              object-fit="cover"
              mr="8px"
              ml="6px"
            />
            <CBox w="full">
              <CLink
                :href="`/clubs/${v.slug}`"
                color="#0278B7"
                d="inline-block"
              >
                <CText
                  color="#0278B7"
                  fontWeight="600"
                  fontSize="14px"
                  d="inline-block"
                  :_hover="{ textDecoration: 'underline' }"
                >
                  {{ truncateText(v.name, 15) }}
                </CText>
              </CLink>
              <CBox d="flex">
                <CText
                  fontSize="10px"
                  fontWeight="400"
                  ml="1"
                  d="flex"
                  color="#9e9e9e"
                >
                  <CText color="#4a4a4a" fontWeight="600" mr="4px">
                    {{ v.total_member }}
                  </CText>
                  Members
                </CText>
              </CBox>
              <CButton
                v-if="v.status === 'pending'"
                :bg="isUrlCopied !== i ? 'white' : 'main'"
                :color="isUrlCopied !== i ? 'main' : 'white'"
                :border="isUrlCopied !== i ? '#29AAFE 1px solid' : ''"
                :_hover="{ opacity: '0.8' }"
                size="0"
                py="10px"
                px="12px"
                h="0"
                w="80px"
                fontSize="10px"
                fontWeight="600"
                @click="detailClubHandler(v.slug)"
              >
                Requested
              </CButton>
              <CButton
                v-else-if="
                  (currentUser &&
                    !currentUser.urole_id &&
                    v.is_joined === 0 &&
                    v.status === 'decline') ||
                  (currentUser &&
                    !currentUser.urole_id &&
                    v.is_joined === 0 &&
                    v.status === 'not_joined') ||
                  (currentUser &&
                    !currentUser.urole_id &&
                    v.is_joined === 0 &&
                    v.status === 'accept') ||
                  !currentUser
                "
                bg="main"
                color="white"
                :_hover="{ opacity: '0.95' }"
                size="0"
                py="10px"
                px="12px"
                h="0"
                w="80px"
                fontSize="10px"
                fontWeight="600"
                @click="currentUser ? openModal(i) : redirectToSignIn()"
              >
                Join
              </CButton>
              <CButton
                v-else-if="
                  (!currentUser.urole_id && v.is_joined !== 0) ||
                  !currentUser ||
                  currentUser.urole_id
                "
                :bg="isUrlCopied !== i ? 'white' : 'main'"
                :color="isUrlCopied !== i ? 'main' : 'white'"
                :border="isUrlCopied !== i ? '#29AAFE 1px solid' : ''"
                :_hover="{ opacity: '0.8' }"
                size="0"
                py="10px"
                px="12px"
                h="0"
                w="80px"
                fontSize="10px"
                fontWeight="600"
                @click="detailClubHandler(v.slug)"
              >
                Detail
              </CButton>
            </CBox>
          </CBox>
        </CBox>
      </CFlex>
    </CBox>
    <CBox v-else>
      <CBox
        bg="white"
        d="flex"
        alignItems="center"
        justifyContent="center"
        rounded="md"
        py="2"
        shadow="sm"
        mt="4"
      >
        <CText v-if="!query" textAlign="center">
          You don't have a club yet, join club
        </CText>
        <CText v-else textAlign="center">
          Clubs containing the word '<CText
            d="inline-block"
            fontWeight="semibold"
            >{{ query }}</CText
          >', were not found
        </CText>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CBox, CImage, CText, CFlex, CLink } from "@chakra-ui/vue";
import { GET_OFFICIAL_CLUBS } from "@/store/club.module";
import JoinClub from "@/components/modal/JoinClub.vue";
import { POST_CLUB_LEAVE_MEMBER } from "@/store/club.module";
import Swal from "sweetalert2";
import config from "@/ConfigProvider";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    CBox,
    CImage,
    CText,
    CFlex,
    CLink,
    Loading,
    JoinClub,
  },
  data() {
    return {
      currentClubIndex: null,
      isLoading: true,
      currentPage: 1,
      currentSlug: "",
      currentTitle: "",
      currentIsNeedApproval: false,
      currentRule: "",
      isModalOpen: false,
      isUrlCopied: -1,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    officialClubs() {
      return this.$store.getters.officialClubs || [];
    },
    query() {
      return this.$route.query.q;
    },
  },
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    redirectToSignIn() {
      window.location.href =
        config.value("GETHIRED_WEB_URL") +
        `/signin?next=${window.location.href}`;
    },
    detailClubHandler(v) {
      const basePath = this.$route.params.track
        ? `/track/${this.$route.params.track}/clubs/${v}`
        : `/clubs/${v}`;

      const newPath = `${basePath}`;
      window.location.href = newPath;
    },
    async handleLeave(slug) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, leave!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let params = {
              slug: slug,
            };
            await this.$store.dispatch(POST_CLUB_LEAVE_MEMBER, params);
            Swal.fire({
              title: "Leaved club!",
              text: "You has been leaved the club.",
              icon: "success",
            }).then(() => {
              window.location.reload();
            });
          } catch (error) {
            console.error("Error leaving club:", error);
            Swal.fire({
              title: "Error",
              text: "An error occurred while leaving the club.",
              icon: "error",
            });
          }
        }
      });
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    async getData() {
      try {
        const params = {
          data: {
            page: this.currentPage,
            limit: 10,
            sortBy: "total_request",
            sort: "DESC",
            appId: this.id,
            keyword: this.query,
          },
        };

        await this.$store.dispatch(GET_OFFICIAL_CLUBS, params);
      } catch (err) {
        console.log(err);
      }
    },
    formatDate(dateString) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const date = new Date(dateString);
      const monthNumber = date.getMonth() + 1; // Months are zero-based
      const year = date.getFullYear();

      return `${monthNumber} ${months[monthNumber - 1]} ${year}`;
    },
    openModal(index) {
      this.currentSlug = this.officialClubs.data[index].slug;
      this.currentTitle = this.officialClubs.data[index].name;
      this.currentRule = this.officialClubs.data[index].rule;
      this.currentIsNeedApproval =
        this.officialClubs.data[index].is_need_approval;
      this.isModalOpen = true;
    },
    closeModal(isSubmmited = false) {
      this.isModalOpen = false;
      if (isSubmmited) {
        this.getData();
      }
    },
    getTypeLabel(type) {
      return type === "komunitas"
        ? "Community"
        : type === "kampus"
        ? "University"
        : type === "perusahaan"
        ? "Company"
        : "Event";
    },
    copyUrl(index) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const path = `/clubs/${this.officialClubs.data[index].slug}`;
      const newPath = `${protocol}//${host}${path}`;
      const inviteUrl = newPath;
      const dummyInput = document.createElement("input");
      document.body.appendChild(dummyInput);
      dummyInput.value = inviteUrl;
      dummyInput.select();
      document.execCommand("copy");
      document.body.removeChild(dummyInput);

      // Update the state to indicate that the URL has been copied
      this.isUrlCopied = index;
      Swal.fire({
        title: "Copied",
        text:
          "Club " +
          this.officialClubs.data[index].name +
          " url ready to share.",
        icon: "success",
      });
      // Reset the state after a certain duration (e.g., 2 seconds)
      setTimeout(() => {
        this.isUrlCopied = -1;
      }, 2000);
    },
  },
};
</script>

<style scoped>
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1);
  }
}

.loading-dot {
  animation: bounce 1.5s infinite;
}

.card {
  position: relative;
  overflow: hidden;
}

.join-button {
  transition: all 0.3s ease-in-out;
}

.card:hover .join-button {
  bottom: 10px;
}
</style>
