<template>
  <CBox>
    <CBox
      bg="white"
      borderRadius="5px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CFlex py="20px" mx="20px" alignItems="center" justifyContent="start">
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="16px"
          fontWeight="600"
          color="fontMain"
          >Leaderboard {{ arena_id }}</CText
        >
      </CFlex>

      <CFlex px="20px" alignItems="center" mb="12px">
        <CBox overflow="auto" pb="8px" d="flex">
          <CBox
            flex="0 0 auto"
            d="flex"
            alignItems="center"
            px="14px"
            py="2px"
            borderRadius="5px"
            fontSize="14px"
            border="1px solid"
            :borderColor="
              currentLeaderboardsActiveFilter.type !== 'club'
                ? 'activeMenu'
                : 'bgMain'
            "
            :fontWeight="defaultMetricsLabel === -1 ? 'bold' : '400'"
            cursor="pointer"
            @click.native="resetLeaderboardHandler()"
            mr="6px"
            ><CText
              :color="
                currentLeaderboardsActiveFilter.type !== 'club'
                  ? 'activeMenu'
                  : 'fontMain'
              "
              >All</CText
            >
          </CBox>
          <CBox
            v-for="(v, i) in currentLeaderboardsFilters"
            :key="i"
            flex="0 0 auto"
            d="flex"
            alignItems="center"
            px="14px"
            py="2px"
            borderRadius="5px"
            fontSize="14px"
            border="1px solid"
            :borderColor="
              currentLeaderboardsActiveFilter.id === v.id
                ? 'activeMenu'
                : 'bgMain'
            "
            :fontWeight="defaultMetricsLabel === -1 ? 'bold' : '400'"
            cursor="pointer"
            @click.native="
              filterHandler({
                type: 'club',
                id: v.id,
                name: v.name,
                slug: v.slug,
              })
            "
            mr="6px"
            ><CText
              :color="
                currentLeaderboardsActiveFilter.id === v.id
                  ? 'activeMenu'
                  : 'fontMain'
              "
              >{{ truncateText(v.name, 15) }}</CText
            >
          </CBox>
        </CBox>
      </CFlex>
      <CBox bg="bgSocialBadge" h="37px">
        <CGrid templateColumns="1fr 3fr 2fr" :gap="2" h="inherit">
          <CBox align="center" py="auto" my="auto">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="11px"
              fontWeight="bold"
              color="headerTable"
              >RANK</CText
            >
          </CBox>
          <CBox py="auto" my="auto">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="11px"
              fontWeight="bold"
              color="headerTable"
              >USER</CText
            >
          </CBox>
          <CBox py="auto" my="auto" align="center">
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="11px"
              fontWeight="bold"
              color="headerTable"
              >SCORE</CText
            >
            <CText
              fontFamily="Montserrat"
              fontStyle="normal"
              fontSize="9px"
              color="headerTable"
              >({{ renderLabel() }})</CText
            >
          </CBox>
        </CGrid>
      </CBox>
      <CBox
        v-if="currentLeaderboardsList && currentLeaderboardsList.length > 0"
      >
        <CBox
          px="8px"
          h="60px"
          v-for="(v, i) in currentLeaderboardsList.slice(0, 5)"
          :key="i"
        >
          <CGrid templateColumns="1fr 3fr 2fr" :gap="2" h="inherit">
            <CBox align="center" py="auto" my="auto">
              <CImage
                v-if="v.rank === 1"
                :src="require('@/assets/icon/ic-first-medal.png')"
              />
              <CImage
                v-else-if="v.rank === 2"
                :src="require('@/assets/icon/ic-second-medal.png')"
              />
              <CImage
                v-else-if="v.rank === 3"
                :src="require('@/assets/icon/ic-third-medal.png')"
              />
              <CText
                v-else
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="14px"
                fontWeight="600"
                color="fontMain"
                >#{{ i + 1 }}</CText
              >
            </CBox>
            <CBox py="auto" my="auto">
              <CBox d="block">
                <CBox d="inline-flex">
                  <CText
                    fontFamily="Montserrat"
                    fontStyle="normal"
                    fontSize="14px"
                    fontWeight="400"
                    >{{ v.full_name }}</CText
                  >
                </CBox>
              </CBox>
            </CBox>
            <CBox
              align="center"
              py="auto"
              my="auto"
              class="pointer"
              @click.native="
                $route.params.track
                  ? $router.push({
                      name: 'Track Detail Job',
                      params: {
                        track: $route.params.track,
                        id: v.result_id,
                      },
                    })
                  : $router.push({
                      name: 'Detail Job',
                      params: {
                        id: v.result_id,
                      },
                    })
              "
            >
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="14px"
                fontWeight="600"
                color="activeMenu"
                >{{ v.point }}</CText
              >
              <CText
                fontFamily="Montserrat"
                fontStyle="normal"
                fontSize="10px"
                color="activeMenu"
                >{{ formatLabel(v) }}</CText
              >
            </CBox>
          </CGrid>
        </CBox>
        <CCollapse :isOpen="isOpen">
          <CBox
            px="8px"
            h="60px"
            v-for="(v, i) in currentLeaderboardsList.slice(5, 10)"
            :key="i"
          >
            <CGrid templateColumns="1fr 3fr 2fr" :gap="2" h="inherit">
              <CBox align="center" py="auto" my="auto">
                <CImage
                  v-if="v.rank === 1"
                  :src="require('@/assets/icon/ic-first-medal.png')"
                />
                <CImage
                  v-else-if="v.rank === 2"
                  :src="require('@/assets/icon/ic-second-medal.png')"
                />
                <CImage
                  v-else-if="v.rank === 3"
                  :src="require('@/assets/icon/ic-third-medal.png')"
                />
                <CText
                  v-else
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="600"
                  color="fontMain"
                  >#{{ i + 6 }}</CText
                >
              </CBox>
              <CBox py="auto" my="auto">
                <CBox d="block">
                  <CBox d="inline-flex">
                    <CText
                      fontFamily="Montserrat"
                      fontStyle="normal"
                      fontSize="14px"
                      fontWeight="400"
                      >{{ v.full_name }}</CText
                    >
                  </CBox>
                </CBox>
              </CBox>
              <CBox
                align="center"
                py="auto"
                my="auto"
                class="pointer"
                @click.native="
                  $route.params.track
                    ? $router.push({
                        name: 'Track Detail Job',
                        params: {
                          track: $route.params.track,
                          id: v.result_id,
                        },
                      })
                    : $router.push({
                        name: 'Detail Job',
                        params: {
                          id: v.result_id,
                        },
                      })
                "
              >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="14px"
                  fontWeight="600"
                  color="activeMenu"
                  >{{ v.point }}</CText
                >
                <CText
                  fontFamily="Montserrat"
                  fontStyle="normal"
                  fontSize="10px"
                  color="activeMenu"
                  >{{ formatLabel(v) }}</CText
                >
              </CBox>
            </CGrid>
          </CBox>
        </CCollapse>
      </CBox>
      <CText v-else color="gray" textAlign="center" mt="14px">
        Leaderboard is empty
      </CText>
      <CBox
        :m="[
          '20px 20px 13px 20px',
          '20px 20px 13px 20px',
          '20px 20px 20px 20px',
          '20px 20px 20px 20px',
        ]"
        pb="30px"
        align="center"
      >
        <CText
          fontFamily="Montserrat"
          fontStyle="normal"
          fontSize="14px"
          fontWeight="600"
          color="activeMenu"
          class="pointer"
          @click.native="showmoreHandler()"
          >Show More
          <CIcon
            v-if="
              !isOpen &&
              currentLeaderboardsList &&
              currentLeaderboardsList.length > 5
            "
            name="chevron-down"
        /></CText>
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import {
  RESET_LEADERBOARDS,
  GET_LEADERBOARDS,
  GET_LEADERBOARDS_INITIAL,
  SET_LEADERBOARDS_LOADING,
  SET_LEADERBOARDS_ACTIVE_FILTER,
  GET_LEADERBOARDS_FILTERS,
} from "@/store/leaderboard-new.module";

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    slug: {
      type: String,
      default: "",
    },
    track: {
      type: String,
      default: "",
    },
    default_leaderboard: {
      type: String,
      default: "",
    },
    arena_id: {
      type: String,
      default: "",
    },
  },
  watch: {
    id() {
      this.getData();
    },
    async officialClubs() {
      this.$store.commit(RESET_LEADERBOARDS);
      this.isLoading = true;
      this.getData();
    },
    currentLeaderboardsFilters() {},
  },
  computed: {
    officialClubs() {
      return this.$store.getters.officialClubs || [];
    },
    currentLeaderboardsList() {
      return this.$store.getters.currentNewLeaderboardsList;
    },
    currentAppType() {
      return this.$store.getters.currentAppType;
    },
    currentLeaderboardsActiveGroup() {
      return this.$store.getters.currentNewLeaderboardsActiveGroup;
    },
    currentLeaderboardsFilters() {
      // Mengambil data dari store
      const myclubs =
        this.$store.getters.currentNewLeaderboardsFilters.club || [];
      const officialclubs =
        this.$store.getters.currentNewLeaderboardsFilters["official club"] ||
        [];

      // Menggabungkan kedua array
      const combinedClubs = [...myclubs, ...officialclubs];

      // Menghapus duplikat berdasarkan ID, prioritas untuk data di `myclubs`
      const uniqueClubs = combinedClubs.reduce((acc, club) => {
        if (!acc[club.id]) {
          acc[club.id] = club;
        }
        return acc;
      }, {});

      // Pisahkan klub berdasarkan kondisi
      const competitiveJoined = [];
      const competitiveNotJoined = [];
      const nonCompetitiveJoined = [];

      Object.values(uniqueClubs).forEach((club) => {
        if (
          club.is_competition &&
          officialclubs.some((official) => official.id === club.id)
        ) {
          competitiveJoined.push(club);
        } else if (
          typeof club.is_competition === "undefined" &&
          typeof club.is_joined === "undefined"
        ) {
          competitiveNotJoined.push(club);
        } else {
          nonCompetitiveJoined.push(club);
        }
      });

      // Gabungkan array sesuai urutan prioritas
      const sortedClubs = [
        ...competitiveJoined,
        ...competitiveNotJoined,
        ...nonCompetitiveJoined,
      ];

      return sortedClubs;
    },
    currentLeaderboardsActiveFilter() {
      return this.$store.getters.currentNewLeaderboardsActiveFilters;
    },
  },
  mounted() {
    this.$store.commit(RESET_LEADERBOARDS);
    this.getData();
  },
  data() {
    return {
      query: undefined,
      isOpen: false,
      isLoading: true,
    };
  },
  methods: {
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    showmoreHandler() {
      if (
        this.isOpen ||
        (this.currentLeaderboardsList &&
          this.currentLeaderboardsList.length < 5)
      ) {
        const basePath = this.$route.params.track
          ? `/track/${this.$route.params.track}/leaderboard/${this.id}`
          : `/leaderboard/${this.id}`;

        const queryParams =
          this.currentLeaderboardsActiveFilter.type === "club"
            ? `?club=${this.currentLeaderboardsActiveFilter.slug}`
            : "";

        const newPath = `${basePath}${queryParams}`;

        // Navigate to the new path and reload the page
        window.location.href = newPath;
      }
      this.isOpen = true;
    },
    handleDetailJob(id) {
      if (id == this.$route.params.id) return;
      this.$router.push({ name: "Detail Job", params: { id } });
    },
    async getData() {
      try {
        this.$store.commit(SET_LEADERBOARDS_LOADING, true);

        await this.$store.dispatch(GET_LEADERBOARDS_FILTERS, {
          id: this.id,
        });

        if (
          this.isLoading &&
          this.currentLeaderboardsFilters &&
          this.currentLeaderboardsFilters.length > 0
        ) {
          this.$store.commit(SET_LEADERBOARDS_ACTIVE_FILTER, {
            type: "club",
            id: this.currentLeaderboardsFilters[0].id,
            name: this.currentLeaderboardsFilters[0].name,
            slug: this.currentLeaderboardsFilters[0].slug,
          });
        }

        if (this.currentLeaderboardsActiveFilter.type === "club") {
          this.query = {
            ["club"]: this.currentLeaderboardsActiveFilter.slug,
          };
        } else {
          this.query = undefined;
        }

        await this.$store.dispatch(GET_LEADERBOARDS_INITIAL, {
          id: this.id,
        });

        await this.$store.dispatch(GET_LEADERBOARDS, {
          id: this.id,
          rank: this.default_leaderboard,
        });

        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    resetLeaderboardHandler() {
      this.$store.commit(RESET_LEADERBOARDS);
      this.getData();
    },
    filterHandler(filter) {
      this.$store.commit(SET_LEADERBOARDS_ACTIVE_FILTER, {
        type: filter.type ? filter.type : "main",
        id: filter.id ? filter.id : null,
        name: filter?.name,
        slug: filter?.slug,
      });
      this.getData();
    },
    formatDuration(duration) {
      if (["LOGIC", "PLAYGROUND"].includes(this.currentAppType.type)) {
        const date = new Date(duration);
        const minutes = date.getMinutes()
          ? `${date.getMinutes()} Minutes,`
          : "";

        const seconds = date.getSeconds()
          ? `${date.getSeconds()} Seconds `
          : "-";
        return `${minutes} ${seconds}`;
      } else {
        return Math.round(duration) / 1000 + "ms";
      }
    },
    isTeamMode() {
      return this.currentAppType?.settings?.mode === "team";
    },
    formatToLocalDate(date) {
      return (
        new Date(date).toLocaleString("id-ID", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          timeZone: this.defaultTimezone,
        }) ?? "-"
      );
    },
    formatLabel(data) {
      if (this.currentAppType.default_leaderboard === "performance") {
        return this.formatDuration(data.duration);
      } else if (
        this.currentAppType.default_leaderboard === "first-success" &&
        data.point > 0
      ) {
        return this.formatToLocalDate(data.finished_date);
      } else {
        return "-";
      }
    },
    renderLabel() {
      const labels = {
        performance: "DURATION",
        "first-success": "FINISH TIME",
      };

      if (["LOGIC", "PLAYGROUND"].includes(this.currentAppType.type)) {
        return labels[this.currentAppType.default_leaderboard];
      } else {
        return "APP Response";
      }
    },
  },
};
</script>
